module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"w85 Static Site Starter","short_name":"w85 Starter","description":"Starter for building static sites at werk85","homepage_url":"https://starter.showcase.werk85.rocks","start_url":"/","background_color":"#fff","theme_color":"#007bff","display":"standalone","icons":[{"src":"/img/android-chrome-36x36.png","sizes":"36x36","type":"image/png"},{"src":"/img/android-chrome-48x48.png","sizes":"48x48","type":"image/png"}],"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"configDir":"/Users/david/vhosts/w85-static-site-starter/gatsby-api"},
    }]
